import React from 'react';

import { type SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { usePostHog } from 'posthog-js/react';

import { isLocalStorageAvailable } from 'lib/ts-utils';
import { FeatureFlagStoryblok } from 'types/storyblok-component-types';

interface StoryContent {
  blocks: SbBlokData[];
}

interface Story {
  content: StoryContent;
  cv: number;
}

interface FeatureFlagProps {
  blok: FeatureFlagStoryblok;
  story: Story;
  version: string;
}

const FeatureFlag: React.FC<FeatureFlagProps> = ({ blok, story, version, ...props }) => {
  const client = usePostHog();
  const [on, setOn] = React.useState(false);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('features-off') !== null) {
      setOn(false);
      return;
    }
    if (params.get('features-on') !== null) {
      setOn(true);
      return;
    }
    if (version === 'draft' && blok.force_on) {
      setOn(true);
      return;
    }
    const phVariableOn = `${blok.feature}-present`;
    const phVariableOff = `${blok.feature}-absent`;
    client.unregister(phVariableOn);
    client.unregister(phVariableOff);
    client.onFeatureFlags(() => {
      if (client.isFeatureEnabled(blok.feature)) {
        // eslint-disable-next-line no-console
        console.debug('%s is present', blok.feature);
        setOn(true);
        client.register({ [phVariableOn]: window.location.pathname });
        if (isLocalStorageAvailable()) {
          localStorage.setItem('utm-feature', phVariableOn);
        }
      } else {
        // eslint-disable-next-line no-console
        console.debug('%s is not absent', blok.feature);
        setOn(false);
        client.register({ [phVariableOff]: window.location.pathname });
        if (typeof localStorage !== 'undefined') {
          localStorage.removeItem('utm-feature');
        }
      }
    });
    return () => {
      // eslint-disable-next-line no-console
      console.debug('unregister %s', blok.feature);
      client.unregister(phVariableOn);
      client.unregister(phVariableOff);
      if (isLocalStorageAvailable()) {
        localStorage.removeItem('utm-feature');
      }
    };
  }, [blok, version, client]);

  // eslint-disable-next-line no-console
  console.log('ffb: %o', { on, f: blok.feature, bb: blok.blocks });

  if (on) {
    return (blok.blocks || []).map((b) => <StoryblokComponent blok={b} key={b._uid} story={story} {...props} {...storyblokEditable(b)} />);
  }
  return null;
};

export default FeatureFlag;
