import { storyblokEditable } from '@storyblok/react';
import ReactMarkdown from 'react-markdown';

import SvgIcon from 'components/ui/svg_icon';
import { cx, titleHn } from 'lib/utils';

import styles from './pros_and_cons.module.scss';

const boldify = (text) => {
  if (!text) {
    return '';
  }
  // <strong>foo</strong: bar -> show as two lines
  if (text instanceof Array) {
    if (typeof text[1] === 'string' && text[1][0] === ':') {
      return (
        <dl>
          <dt>{text[0]}</dt>
          <dd>{text[1].slice(2)}</dd>
        </dl>
      );
    }
    // any other array, just return what they sent
    return text;
  }
  const split = text.split(/--/).map((t) => t.trim());
  // if no double dash, return the text as normal
  if (split.length === 1) {
    return (
      <dl>
        <dd>{split[0]}</dd>
      </dl>
    );
  }
  // if double dash, split onto two lines and return the first part bold, second part normal
  return (
    <dl>
      <dt>{split[0]}</dt>
      <dd>{split[1]}</dd>
    </dl>
  );
};

const proComponents = {
  li: (props) => (
    <li>
      <div className={cx(styles.icon, styles['icon--success'])}>
        <SvgIcon icon="check-solid" />
      </div>
      <section>
        <span>{boldify(props.children)}</span>
      </section>
    </li>
  ),
};

const errorComponents = {
  li: (props) => (
    <li>
      <div className={cx(styles.icon, styles['icon--error'])}>
        <SvgIcon icon="xmark-solid" />
      </div>
      <section>
        <span>{boldify(props.children)}</span>
      </section>
    </li>
  ),
};

const ProsAndCons = ({ blok }) => (
  <section className={`${cx(styles['n4-pros-and-cons'], !blok?.columns && styles['n4-pros-and-cons--column'])} ${blok.component}`} {...storyblokEditable(blok)}>
    <div className={cx(styles.container, !blok?.columns && styles['container--column'])}>
      <section className={styles.column}>
        {titleHn({ title: blok.pros_title ? blok.pros_title : 'Positives', heading: blok.heading_size || 'h4' })}
        <ReactMarkdown className="n4-markdown-blok" components={proComponents}>
          {blok.pros}
        </ReactMarkdown>
      </section>
      <section className={styles.column}>
        {titleHn({ title: blok.cons_title ? blok.cons_title : 'Negatives', heading: blok.heading_size || 'h4' })}
        <ReactMarkdown className="n4-markdown-blok" components={errorComponents}>
          {blok.cons}
        </ReactMarkdown>
      </section>
    </div>
  </section>
);

export default ProsAndCons;
