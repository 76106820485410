import React from 'react';

import { type SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { usePostHog } from 'posthog-js/react';

import { isLocalStorageAvailable } from 'lib/ts-utils';
import { FeatureFlagVariantsStoryblok } from 'types/storyblok-component-types';

interface StoryContent {
  blocks: SbBlokData[];
}

interface Story {
  content: StoryContent;
  cv: number;
}

interface FeatureFlagProps {
  blok: FeatureFlagVariantsStoryblok;
  story: Story;
  version: string;
}

const FeatureFlagVariants: React.FC<FeatureFlagProps> = ({ blok, story, version, ...props }) => {
  const client = usePostHog();
  const [variant, setVariant] = React.useState<string | undefined>();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('features-off') !== null) {
      setVariant('nofeatureforyou');
      return;
    }
    if (params.get('features-on') !== null) {
      setVariant('*');
      return;
    }
    if (params.get(blok.feature)) {
      setVariant(params.get(blok.feature) as string);
      return;
    }
    if (!client) {
      return;
    }
    // Remove them all so we don't have to worry about orphans
    const variants = blok.blocks.map((b) => b.variant);
    variants.forEach((v) => {
      const phVariable = `${blok.feature}-${v}`;
      client.unregister(phVariable);
      if (isLocalStorageAvailable()) {
        localStorage.removeItem('utm-feature');
      }
    });
    client.onFeatureFlags(() => {
      if (version === 'draft' && blok.force_variant) {
        setVariant(blok.force_variant);
      } else {
        const v = client.getFeatureFlag(blok.feature) as string;
        // eslint-disable-next-line no-console
        console.debug('%s -> %s', blok.feature, v);
        if (v) {
          const phVariable = `${blok.feature}-${v}`;
          client.register({ [phVariable]: window.location.pathname });
          setVariant(v);
          if (typeof localStorage !== 'undefined') {
            localStorage.setItem('utm-feature', phVariable);
          }
        }
      }
    });
    return () => {
      variants.forEach((v) => {
        const phVariable = `${blok.feature}-${v}`;
        client.unregister(phVariable);
        if (typeof localStorage !== 'undefined') {
          localStorage.removeItem('utm-feature');
        }
      });
    };
  }, [client, blok, version]);

  const components = blok.blocks.filter((b) => variant === '*' || variant?.toLowerCase() === 'all' || b.variant === variant);
  if (components.length) {
    return components.map((c) => <StoryblokComponent blok={c} key={c._uid} story={story} {...props} {...storyblokEditable(c)} />);
  }
  return null;
};

export default FeatureFlagVariants;
