import * as React from 'react';

import Footer from 'components/bloks/n4/footer';
import logger from 'lib/logger';
import setupSiteControl from 'lib/site_control';
import { setupTracking } from 'lib/tracking';
import { isLocalStorageAvailable } from 'lib/ts-utils';
import setupUserWay from 'lib/user_way';
import { tw } from 'lib/utils';

import fonts from './fonts';
import Meta from './meta';
import StoryblokNav from './storyblok_nav';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'components.n4layout' });

// Define which pages should show the disclaimer
const popupSlugs = ['earplugs', 'hearables', 'hearing-aids', 'otc-hearing-aids'];

const isDisclaimerShowing = (is404, slug, ssr) => {
  if (is404 || !slug || !popupSlugs.some((s) => slug.startsWith(s))) {
    return false;
  }
  if (ssr) return true;
  if (typeof window !== 'undefined' && window.ht_disclaimer_closed) return false;
  if (isLocalStorageAvailable() && localStorage.getItem('ht_disclaimer_closed')) return false;
  return true;
};

const saveDisclaimerState = () => {
  if (typeof window !== 'undefined') {
    window.ht_disclaimer_closed = true;
  }
  if (isLocalStorageAvailable()) {
    localStorage.setItem('ht_disclaimer_closed', 'true');
  }
};

const N4Layout = ({ preview, children, ...props }) => {
  const [isHomepage, setIsHomepage] = React.useState(props.story?.full_slug === '');
  const [showDisclaimer, setShowDisclaimer] = React.useState(isDisclaimerShowing(props.is404, props.story?.full_slug, true));
  const [disclaimerHeight, setDisclaimerHeight] = React.useState(0); // Store disclaimer height from StoryblokNav

  React.useEffect(() => {
    const homepageStatus = !props.is404 && props.story?.full_slug === '';
    const disclaimerStatus = isDisclaimerShowing(props.is404, props.story?.full_slug, true);

    if (isHomepage !== homepageStatus) setIsHomepage(homepageStatus);
    if (showDisclaimer !== disclaimerStatus) setShowDisclaimer(disclaimerStatus);
  }, [props.is404, props.story?.full_slug, isHomepage, showDisclaimer]);

  React.useEffect(() => {
    setupTracking();
    setupSiteControl();
    setupUserWay();
  }, []);

  const hideDisclaimer = () => {
    setShowDisclaimer(false);
    saveDisclaimerState();
  };

  let paddingTop = '80px';
  if (showDisclaimer) {
    paddingTop = `${disclaimerHeight + 80}px`;
  } else if (isHomepage) {
    paddingTop = '0px';
  }

  const memoizedSetDisclaimerHeight = React.useCallback((height) => {
    setDisclaimerHeight(height);
  }, []);

  return (
    <section className={tw('ht-wrapper', fonts.hankenGrotesk.className, 'bg-cream')} style={{ paddingTop }}>
      {props.story?.full_slug !== 'components/nav' && (
        <StoryblokNav
          isHomepage={isHomepage}
          showDisclaimer={showDisclaimer}
          hideDisclaimer={hideDisclaimer}
          setDisclaimerHeight={memoizedSetDisclaimerHeight}
          {...props}
        />
      )}
      <div className="ht-n4">{children}</div>
      <Meta {...props} />
      {!props.hideFooter && <Footer isHomepage={isHomepage} />}
    </section>
  );
};

export default N4Layout;
