/* eslint-disable  no-bitwise */
import { htRandom } from 'lib/ts-utils';

import logger, { logRemotelyClient } from './logger';

const defaultLog = logger({ category: 'ht_api' });

const baseUrl = process.env.API_URL;
const FRIENDLY_ERROR = 'An unexpected error occurred. Please contact support.';

// TODO: security if needed

const mapToParams = (map, isFirstParam = true) => {
  if (Object.keys(map).length) {
    const params = Object.entries(map)
      .filter(([, v]) => !!v)
      .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
      .join('&');
    if (isFirstParam) {
      return `?${params}`;
    }
    return `&${params}`;
  }
  return '';
};

const generateFixedLengthUniqueNumber = (str, length) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  hash = Math.floor(hash * htRandom() * 10 ** length);
  const numberString = Math.abs(hash).toString().slice(0, length);
  const paddedNumberString = numberString.padStart(length, '0');
  return paddedNumberString;
};

/*
 * api should return either:
 * { <successful result> } -> worked
 * or
 * { error } -> handled error, display to user
 * or
 * { exception, error } -> unhandled exception, display generic message to user
 *
 * fallback says what to return if there is an exception
 * handled errors will always be returned
 * if fallback is 'friendly', return a generic friendly error
 */
const fetchApi = async ({ path, version = 'v1', method = 'GET', variables = {}, fallback, origin, log = defaultLog }) => {
  const random = String(generateFixedLengthUniqueNumber(path, 10));
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'x-requestId': log.requestId,
      'x-random': random,
    },
    credentials: 'include',
  };
  const t = new Date().getTime();
  const vars = { ...variables, 'x-random': random, 'x-requestId': log.requestId, origin, 'x-time': t };
  if (/POST|PUT|PATCH/i.test(method)) {
    options.body = JSON.stringify(vars);
  }
  const isFirstParam = !/\?/.test(path);
  const params = method.toLowerCase() === 'get' ? mapToParams(vars, isFirstParam) : '';
  const url = `${baseUrl}${version}${path[0] === '/' ? '' : '/'}${path}${params}`;
  log.info('fetch: %s', url);
  // Note to self: url is long to log, but it's handy to grab and test in the browser
  log.debug('fetch: %o', { url, variables, requestId: log.requestId, origin });
  try {
    const result = await fetch(url, options);
    const json = await result.json();
    if (json.exception) {
      log.error('handled exception: url: %s, params: %o, ex: %o, origin: %s', url, params, json, origin);
      if (typeof window !== 'undefined') {
        logRemotelyClient(
          'handled exception: url: %s, params: %o, ex: %o, origin: %s [%d] location: %s',
          url,
          params,
          json,
          origin,
          new Date().getTime(),
          window.location.href
        );
      }
      if (fallback === 'friendly') {
        return {
          error: FRIENDLY_ERROR,
          ...json,
        };
      }
      if (fallback) {
        return fallback;
      }
      throw new Error('Unhandled exception in fetch api');
    }
    return json;
  } catch (ex) {
    log.error('unhandled exception: url: %s, params: %o, ex: %o, origin: %s, time: %d', url, params, ex, origin, t);
    if (!(/googlebot/i.test(navigator.userAgent) && ex.message === 'Failed to fetch')) {
      // googlebot has a weird env and this happens a lot so ignore those
      if (typeof window !== 'undefined') {
        logRemotelyClient(
          'unhandled exception: url: %s, params: %o, ex: %o, origin: %s, time: %d, location: %s',
          url,
          params,
          ex,
          origin,
          t,
          window.location.href
        );
      }
    }
    if (fallback === 'friendly') {
      return {
        exception: ex.message,
        error: FRIENDLY_ERROR,
      };
    }
    if (fallback) {
      return fallback;
    }
    throw ex;
  }
};

/* eslint-disable import/prefer-default-export -- because I think we'll be adding more */
export { fetchApi };
