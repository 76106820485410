import React from 'react';

import { Button } from 'components/common-n4/button';
import { Link } from 'components/common-n4/link';
import { useAb } from 'hooks';
import { AbLinkPosition, type AbButtonPosition } from 'lib/ab-tests';
import { htRandom, utmize } from 'lib/ts-utils';
import { tw } from 'lib/utils';

interface SimplePriceButtonProps extends React.PropsWithChildren {
  uuid: string;
  url: string;
  position: AbButtonPosition | AbLinkPosition;
  origin: string;
  releaseName: string;
  slug: string;
  seller: string;
  display: 'link' | 'button';
  forceText?: string;
  forceCta?: string;
  className?: string;
  value?: string;
  onClick?: (value: string) => void;
  campaign?: string; // override utm campaign
  eventProperties: Record<string, string | number>;
}

const elementType = 'button';
const event = 'AffiliateAction';

interface LinkStateProps {
  seller: string;
  link: string;
  random: string;
  substitutions: Record<string, string>;
}

const SimplePrice: React.FC<SimplePriceButtonProps> = ({
  uuid,
  url,
  position,
  origin,
  releaseName,
  slug,
  seller,
  display,
  forceText,
  forceCta,
  className,
  value,
  onClick,
  campaign,
  eventProperties,
  children,
}) => {
  const [clicked, setClicked] = React.useState(0);
  const [linkState, setLinkState] = React.useState<LinkStateProps>({ seller, link: '', random: '', substitutions: {} });

  const { cta, text } = useAb({
    testName: position,
    uuid,
    event,
    origin,
    skip: !linkState.link,
    clicked,
    eventProperties: { ...eventProperties, elementType, release: releaseName, seller, link: linkState.link, random: linkState.random },
    substitutions: linkState.substitutions,
    fallbackText: 'View Details',
    forceCta,
    forceText,
  });

  React.useEffect(() => {
    const random = String(Math.floor(htRandom() * 10 ** 8));
    const link = utmize({ url, content: position, term: random, campaign: campaign || 'lead-gen' });
    setLinkState((prev) => ({ ...prev, link, random, substitutions: { release: releaseName, seller } }));
  }, [url, position, releaseName, seller, campaign]);

  if (display === 'link') {
    return (
      <Link
        variant="primary"
        className={tw('!justify-normal !gap-[0.2rem] !p-0', className)}
        href={linkState.link}
        onClick={() => {
          setClicked((count) => count + 1);
          if (onClick && value) {
            onClick(value);
          }
          return true;
        }}
        shouldOpenNewTab
        data-track
        data-event-name="Link"
        data-event-position={position}
        data-event-product={slug}
        data-event-cta={cta}
        data-event-seller={seller}
        data-event-url={linkState.link}
      >
        {children || text}
      </Link>
    );
  }

  return (
    <Button.LinkPrimary
      className={className || '!w-full'}
      onClick={() => {
        setClicked((count) => count + 1);
        if (onClick && value) {
          onClick(value);
        }
        return true;
      }}
      href={linkState.link}
      shouldOpenNewTab
      loader="false"
      data-track
      data-event-name="Button"
      data-event-position={position}
      data-event-product={slug}
      data-event-cta={cta}
      data-event-seller={linkState.seller}
      data-event-url={linkState.link}
    >
      {text}
    </Button.LinkPrimary>
  );
};

export default SimplePrice;
