import type { HearingAid, Level, Model, Offer, ReleasePrices } from 'types/release';

export const isProduction = (process.env.NEXT_PUBLIC_ENV || process.env.SERVER_ENV) === 'production';
export const isDevelopment = (process.env.NEXT_PUBLIC_ENV || process.env.SERVER_ENV || process.env.NODE_ENV || 'development') === 'development';
export const isTestMode = process.env.NEXT_PUBLIC_TEST_MODE === 'true' || process.env.TEST_MODE === 'true';

export const htRandom = () => {
  if (process.env.TEST_MODE === 'true' || process.env.NEXT_PUBLIC_TEST_MODE === 'true') {
    return 0;
  }
  return Math.random();
};

const isLocalStorageAvailable = () => {
  try {
    if (typeof window === 'undefined') {
      return false;
    }
    if (typeof window.localStorage === 'undefined') {
      return false;
    }
    window.localStorage.setItem('__test', 'yes');
    window.localStorage.removeItem('__test');
    return true;
  } catch (ex) {
    return false;
  }
};

export { isLocalStorageAvailable };

interface UtmizeProps {
  url: string;
  source?: string;
  medium?: string;
  campaign?: string;
  term?: string;
  content?: string;
}
export const utmize = ({ url, source, medium = 'button', campaign, term = 'lead-term', content }: UtmizeProps): string => {
  const newUrl = new URL(url);
  if (campaign) {
    newUrl.searchParams.set('utm_campaign', campaign);
  } else if (isLocalStorageAvailable()) {
    const c = localStorage.getItem('utm-campaign');
    if (c) {
      newUrl.searchParams.set('utm_campaign', c);
    }
  }
  newUrl.searchParams.set('utm_medium', medium);
  if (content) {
    newUrl.searchParams.set('utm_content', content);
  }
  newUrl.searchParams.set('utm_term', term);
  if (source) {
    newUrl.searchParams.set('utm_source', source);
  }
  return newUrl.toString();
};

export const scrollToAnchorWithOffset = (id: string, offset: number) => {
  const target = document.getElementById(id) as HTMLElement;
  const targetOffsetTop = target?.offsetTop || 0;
  window.scrollTo({ top: targetOffsetTop + offset, behavior: 'smooth' });
};

interface OfferWithPosition extends Offer {
  position: number;
}

const priceSort = (o1: OfferWithPosition, o2: OfferWithPosition) => {
  const pos = Math.sign(o1.position - o2.position);
  if (pos !== 0) {
    return pos;
  }
  const a = o1.price,
    b = o2.price;
  const a1 = a.replace(/[^0-9]/g, ''); // remote $ and comma
  const b1 = b.replace(/[^0-9]/g, '');
  const af1 = parseFloat(a1);
  const bf1 = parseFloat(b1);
  const sign = Math.sign(af1 - bf1);
  return sign;
};

export const flattenAndSortOffers = (prices: ReleasePrices): Offer[] =>
  Object.values<Offer[]>(prices?.offers || {})
    .flat()
    .map((o) => ({ ...o, position: prices.sellers[o.sellerId].position }))
    .sort(priceSort);

interface OneTruePriceProps {
  prices?: ReleasePrices;
  model?: Model;
  level?: Level;
  hearingAid?: HearingAid;
}

export const getOneTrueOffer = ({ prices, model, level, hearingAid }: OneTruePriceProps): Offer | null => {
  if (!prices) {
    return null;
  }
  const offers = flattenAndSortOffers(prices);
  let filtered;
  if (hearingAid) {
    filtered = offers.filter((o) => o.hearingAidIds.includes(hearingAid.id));
  } else if (level) {
    filtered = prices.offers[level.id];
  } else if (model) {
    filtered = offers.filter((o) => o.modelIds.includes(model.id));
  } else {
    filtered = offers;
  }
  if (filtered.length === 1) {
    return filtered[0];
  }
  return null;
};

export const getMatchingOffers = ({ prices, model, level, hearingAid }: OneTruePriceProps): Offer[] => {
  if (!prices) {
    return [];
  }
  const offers = flattenAndSortOffers(prices);
  let filtered;
  if (hearingAid) {
    filtered = offers.filter((o) => o.hearingAidIds.includes(hearingAid.id));
  } else if (level) {
    filtered = prices.offers[level.id];
  } else if (model) {
    filtered = offers.filter((o) => o.modelIds.includes(model.id));
  } else {
    filtered = offers;
  }
  return filtered;
};
